<template>
  <div>
    <div class="page-title" type="base"> {{ $t('base.title') }}</div>
    <el-button
        v-on:click="switchToChinese()"
    >{{ $t('base.language_zh') }}
    </el-button>
    <el-button
        v-on:click="switchToEnglish()"
    >{{ $t('base.language_en') }}
    </el-button>

    <el-card>
      <el-row style="margin-left: 0.5vw">
        <el-col :span="22">
          <el-form
              :inline="true"
              style="margin-bottom: 5px"
              class="demo-ruleForm"
          >
            <el-form-item style="margin-bottom: 0">
              <el-form :inline="true" class="demo-ruleForm" ref="pallet_param">
                <el-form-item :label="$t('setting.pallet_setting')"  style="margin-bottom: 0">
                  <!-- <el-input type="number" v-model="pallet_param.long"  placeholder="" > -->
                  <el-input
                      type="number"
                      v-model="pallet_param.length"
                      placeholder=""
                      style="width: 90px"
                  >
                  </el-input
                  >
                  &nbsp({{$t('setting.length')}} {{ length_unit }})
                </el-form-item>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-form-item label="" style="margin-bottom: 0">
                  <!-- <el-input type="number" v-model="pallet_param.wide"  placeholder="" > -->
                  <el-input
                      type="number"
                      v-model="pallet_param.wide"
                      placeholder=""
                      style="width: 90px"
                  >
                  </el-input
                  >
                  &nbsp({{$t('setting.wide')}} {{ length_unit }})
                </el-form-item>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-form-item label="" style="margin-bottom: 0">
                  <!-- <el-input type="number" v-model="pallet_param.high"  placeholder="" > -->
                  <el-input
                      type="number"
                      v-model="pallet_param.high"
                      placeholder=""
                      style="width: 90px"
                  >
                  </el-input
                  >
                  &nbsp({{$t('setting.high')}} {{ length_unit }})
                </el-form-item>
                <el-form-item label="" style="margin-bottom: 0">
                  <el-input
                      type="number"
                      v-model="pallet_param.over_limit"
                      placeholder=""
                      style="width: 90px"
                  >
                  </el-input
                  >
                  &nbsp({{$t('setting.rate')}} %)
                </el-form-item>
              </el-form>
            </el-form-item>

            <el-select
                size="mini"
                v-model="searchParam.country"
                :placeholder="请选择国家"
                @change="change_country()"
            >
              <el-option label="US" value="US"></el-option>
              <el-option label="CA" value="CA"></el-option>
              <el-option label="EU" value="EU"></el-option>
              <el-option label="GB" value="GB"></el-option>
              <el-option label="AU" value="AU"></el-option>
              <el-option label="JP" value="JP"></el-option>
            </el-select>
            <el-button
                icon="el-icon-search"
                type="primary"
                :disable="isLock"
                v-on:click="allocationPallet()"
                style="margin-left: 16px"
            >{{$t('setting.start_simulation')}}
            </el-button
            >
            （{{ item_type }}{{$t('setting.item_limit')}}{{ total_count_limit }}）
          </el-form>
        </el-col>
      </el-row>

      <el-row style="margin-left: 0.5vw">
        <div style="margin-bottom: 5px">
          <el-button type="primary" @click="selectAll()">{{$t('item.select_all')}}</el-button>
          <el-button
              type="primary"
              @click="handleUpload"
              style="margin-right: 10px"
          >{{ $t('setting.batch_input') }}
          </el-button
          >
          <input
              type="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref="fileInput"
              style="display: none"
              @change="handleFileUpload"
          />
          <el-button type="primary" @click="batchDel()">{{ $t('setting.batch_delete') }}</el-button>


          <el-button>
            <a href="http://file.nbhooya.com/Files/20240919/113b5056c17f461196daef8ffcd3bd75.xlsx">{{ $t('setting.download_template') }}</a>
          </el-button>

        </div>
        <el-col :span="22">
          <el-form
              v-loading="item_loading"
              v-for="(item, index) in formInline"
              :inline="true"
              :key="index"
              class="search-param"
              ref="formInline"
          >
            <div @click.stop="getIndex(index)">
              <el-form-item label="">
                <el-checkbox v-model="item.isCurrent"></el-checkbox>
              </el-form-item>
              <el-form-item :label="$t('item.number')">
                <!-- @blur="getItemInfo()" -->
                <el-input
                    v-model="item.cpbh"
                    placeholder=""
                    style="width: 150px"
                    @focus="handleFocus(index)"
                    @change="getItemInfo(index)"
                    @input="resetFormLine()"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('item.quantity')" >
                <el-input
                    type="number"
                    :min="1"
                    v-model="item.qty"
                    placeholder=""
                    style="width: 80px"
                    required:true
					
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('item.length')">
                <el-input
                    v-model="item.bzcd"
                    :disabled="true"
                    placeholder=""
                    style="width: 70px"
                >
                </el-input>
                {{ length_unit }}
              </el-form-item>
              <el-form-item :label="$t('item.wide')">
                <el-input
                    v-model="item.bzkd"
                    :disabled="true"
                    placeholder=""
                    style="width: 70px"
                >
                </el-input>
                {{ length_unit }}
              </el-form-item>
              <el-form-item :label="$t('item.high')">
                <el-input
                    v-model="item.bzgd"
                    :disabled="true"
                    placeholder=""
                    style="width: 70px"
                >
                </el-input>
                {{ length_unit }}
              </el-form-item>
              <el-form-item :label="$t('item.weight')">
                <el-input
                    v-model="item.weight"
                    :disabled="true"
                    placeholder=""
                    style="width: 70px"
                >
                </el-input>
                {{ weight_unit }}
              </el-form-item
              >
              &nbsp
              <el-select
                  size="mini"
                  v-model="item.orientation"
                  clearable
                  :placeholder="$t('item.place_holder')"
                  @change="change_orientation()"
              >
                <el-option :label="$t('item.length_perpendicular')" value="1"></el-option>
                <el-option :label="$t('item.wide_perpendicular')" value="2"></el-option>
                <el-option
                    :label="$t('item.high_perpendicular')"
                    value="3"
                ></el-option>
              </el-select
              >
              &nbsp
              <el-select
                  size="mini"
                  v-model="item.changeable"
                  :placeholder="$t('item.place_holder')"
              >
                <el-option :label="$t('item.changeable')" value="1"></el-option>
                <el-option :label="$t('item.not_changeable')" value="2"></el-option>
              </el-select>
            </div>
          </el-form>
        </el-col>
        <el-col :span="2">
          <i
              class="el-icon-circle-plus-outline"
              type="danger "
              style="font-size: 30px; color: #67c23a; margin-right: 5px"
              @click="addItem"
          ></i>
          <i
              class="el-icon-remove-outline"
              type="danger"
              style="font-size: 30px; color: #f56c6c"
              @click="del(index)"
          ></i>
        </el-col>
      </el-row>
    </el-card>
    <br/>
    <el-row :gutter="20" style="margin-bottom: 12px" class="census-box">
      <el-col :span="12">
        <el-card v-loading="loading">
          <div slot="header" class="clearfix">{{ $t('count.title') }}</div>

          <p
              style="font-weight: bold; text-align: center"
              vertical-align:middle
          >
            {{ $t('count.total') }}&nbsp{{ pallet_count }}&nbsp{{ $t('count.pallet') }}
          </p>
          <el-form
              v-for="(item, index) in pallet_form_data"
              :inline="true"
              :key="index"
              class="demo-ruleForm"
              ref="pallet_form_data"
          >
            <p>{{ $t('count.number') }}{{ index + 1 }}&nbsp{{ $t('count.pallet') }}&nbsp{{ pallet_form_data[index][0].xyz }}</p>
			
            <el-table :sum-text="$t('count.count')" :data="item" show-summary style="width: 100%">
              <el-table-column prop="cpbh" :label="$t('item.number')" width="180">
              </el-table-column>
              <el-table-column prop="qty" :label="$t('item.quantity')" width="180">
              </el-table-column>
              <el-table-column
                  prop="weight"
                  :label="weight_unit == 'lb' ?  $t('item.weight_lb') : $t('item.weight_kg')"
                  width="180"
              >
              </el-table-column>
            </el-table>
			
            <br/>
            <br/>
          </el-form>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card v-loading="loading">
          <div slot="header" class="clearfix">{{ $t('count.display') }}</div>
          <p
              style="font-weight: bold; text-align: center"
              vertical-align:middle
          >
            {{$t('count.total')}}&nbsp{{ pallet_count }}&nbsp{{$t('count.pallet')}} {{$t('count.time')}}{{ time_count }}&nbsp{{$t('count.second')}})
          </p>

          <div class="" id="box">
            <ul>
              <li v-for="(v, index) in palletArray" :key="index">
                <p>{{$t('count.number')}}&nbsp{{ index + 1 }}&nbsp{{$t('count.pallet')}}</p>
                <!-- <img style="width: 640px;height: 480px;" :src="require('../../assets/' + v.file_path)" alt=""> -->
                <!-- Linux -->
                <!-- <img v-bind:src="v.file_path" alt=""> -->

                <img v-bind:src="v.file_path" alt=""/>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getAllocationPalletData,
  getItemInfoByCpbh,
  batchImportPalletData,
} from "@/api/lading.js";

export default {
  name: "Lading",
  components: {},
  data() {
    return {
      item_type: this.$i18n.locale == 'zh' ?'单':'Single ',
      isLock: false, //防止用户频繁点击
      total_count_limit: 2000,
      length_unit: "in",
      weight_unit: "lb",
      searchParam: {
        // 查询条件
        country: "US",
      },
      img: "favicon.ico",
      pallet_count: 0,
      time_count: 0,
      //托盘总统计数据
      pallet_form_data: [],
      current_index: -1,
      formInline: [
        {
          isCurrent: false,
          cpbh: "",
          qty: "100",
          bzcd: "",
          bzkd: "",
          bzgd: "",
          weight: "",
          pickingno: "",
          orientation: "",
          changeable: "1",
        },
      ],
      list: [],
      loading: false,
      item_loading: false,
      palletArray: [],
      pallet_param: {
        length: 48,
        wide: 40,
        high: 100,
        over_limit: 0,
      },
      checkBatchDelete: false,
      index: 0,
    };
  },
  mounted() {
  },
  methods: {
    switchToChinese () {
      this.$i18n.locale = 'zh'
      this.item_type = '单'

    },
    switchToEnglish () {
      this.$i18n.locale = 'en'
      this.item_type = 'Single '
    },

    handleFocus(index) {
      this.current_index = index
      console.log(this.current_index, "this.current_indexhandleFocus")
    },
    // 全选
    selectAll() {
      this.checkBatchDelete = !this.checkBatchDelete
      this.formInline.forEach((item) => {
        item.isCurrent = this.checkBatchDelete;
      });
    },
    // 批量删除
    batchDel() {
      this.formInline = this.formInline.filter((item) => !item.isCurrent);
      this.formInline.forEach((item) => {
        item.isCurrent = false;
      });
    },
    // 导入
    handleUpload() {
      // 清空input file元素的值
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const {data, code} = await batchImportPalletData(formData);
      if (code == 200) {
        this.formInline = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let item_param = {
            cpbh: element.cpbh,
            country: this.searchParam.country,
          };

          getItemInfoByCpbh(item_param).then((res) => {
            let {bzcd, bzgd, bzkd, weight} = res.data;
            let empty_input = {
              isCurrent: false,
              cpbh: element.cpbh,
              qty: element.quantity,
              bzcd: bzcd,
              bzkd: bzkd,
              weight: weight,
              round: "",
              bzgd: bzgd,
              orientation: "",
              changeable: "1",
            };
            this.formInline.push(empty_input);
          });
        }
        console.log(this.formInline, "this.formInline");
      }
    },
    //记录在第几行输入框输入数据
    getIndex(index) {
      this.current_index = index;
      //   console.log(this.current_index, "this.current_index");
    },
    //增加输入框
    addItem() {
      this.total_count_limit = 500;
      if (this.$i18n.locale == 'zh') {
        this.item_type = "多";
      } else {
        this.item_type = "Multipl ";
      }

      let empty_input = {
        isCurrent: false,
        cpbh: "",
        qty: "100",
        bzcd: "",
        bzkd: "",
        round: "",
        weight: "",
        bzgd: "",
        orientation: "",
        changeable: "1",
      };
      this.formInline.push(empty_input);
    },
    // 删除输入框
    del() {

      this.formInline.splice(this.current_index, 1);
    },

    async change_orientation() {
    },

    async change_country() {
      let temp = this.searchParam.country;
      if ((temp == "CA") | (temp == "US")) {
        //把厘米转成英寸
        // if (this.length_unit == 'cm') {
        // 	this.pallet_param.long = (this.pallet_param.long*0.393700787402).toFixed(2)
        // 	this.pallet_param.wide = (this.pallet_param.wide*0.393700787402).toFixed(2)
        // 	this.pallet_param.high = (this.pallet_param.high*0.393700787402).toFixed(2)

        // }
        this.pallet_param.length = 48, this.pallet_param.wide = 40,
            (this.length_unit = "in"), (this.weight_unit = "lb");
      } else {
        //把英寸转成厘米
        // if (this.length_unit == 'in') {
        // 	this.pallet_param.long = (this.pallet_param.long/0.393700787402).toFixed(2)
        // 	this.pallet_param.wide = (this.pallet_param.wide/0.393700787402).toFixed(2)
        // 	this.pallet_param.high = (this.pallet_param.high/0.393700787402).toFixed(2)

        // }
        if ((temp == "AU")) {
          this.pallet_param.length = 116.5, this.pallet_param.wide = 116.5, this.pallet_param.high = 150,
              (this.length_unit = "cm"), (this.weight_unit = "kg");

        } else {
          this.pallet_param.length = 120, this.pallet_param.wide = 80,
              (this.length_unit = "cm"), (this.weight_unit = "kg");
        }

      }
      this.searchParam.country = temp;
      // 重置页面
      this.formInline = [
        {
          cpbh: "",
          qty: "",
          bzcd: "",
          bzkd: "",
          bzgd: "",
          weight: "",
          pickingno: "",
          orientation: "",
          changeable: "1",
        },
      ];
      (this.pallet_form_data = []),
          (this.palletArray = []),
          (this.pallet_count = 0),
          (this.time_count = 0);
    },

    //追加图片
    addPic(palletArray) {
      this.list.push({
        palletArray: palletArray,
      });
    },

    //当产品编号改变时，重置该列的所有值
    async resetFormLine() {
      this.formInline[this.current_index].changeable = "1";
      this.formInline[this.current_index].orientation = "";
      this.formInline[this.current_index].bzcd = undefined;
      this.formInline[this.current_index].bzkd = undefined;
      this.formInline[this.current_index].bzgd = undefined;
      this.formInline[this.current_index].weight = undefined;
      this.formInline[this.current_index].qty = 100;
    },

    //通过产品编号获取产品信息，填充信息至输入框
    async getItemInfo(index) {
      this.item_loading = true;
      let cpbh = this.formInline[this.current_index].cpbh;
      if (cpbh == "") {
        this.item_loading = false;
        return;
      }
      let item_param = {
        cpbh: cpbh,
        country: this.searchParam.country,
      };

      await getItemInfoByCpbh(item_param).then((res) => {
        this.item_loading = false;
        if ((res.code == 200) & (res.data.bzcd == null)) {
          this.$notify({
            title: "警告",
            message: "查询不到产品数据，请核对产品编号！",
            type: "warning",
          });
        } else if (res.code == 500) {
          alert("服务器错误！请联系开发人员。");
        }
        let itemInfo = res.data;
        this.formInline[this.current_index].bzcd = itemInfo.bzcd;
        this.formInline[this.current_index].bzkd = itemInfo.bzkd;
        this.formInline[this.current_index].bzgd = itemInfo.bzgd;
        this.formInline[this.current_index].weight = itemInfo.weight;
      });
    },
    /**
     * 校验物体物品数量是否符合预期
     */
    validate_item_count() {
      let validate_flg = true;
      let item_count = 0;
      this.formInline.forEach((i) => {
        item_count = item_count + parseInt(i.qty);
      });
      // 单物品2000, 多物品500
      if (this.formInline.length == 1) {
        if (item_count > this.total_count_limit) {
          validate_flg = false;
        }
      } else {
        if (item_count > this.total_count_limit) {
          validate_flg = false;
        }
      }

      return validate_flg;
    },

    /**
     * 校验物体是否能放入托盘
     */
    validate_pallet() {
      let pallet_long = this.pallet_param.length * (1 + this.pallet_param.over_limit / 100);
      let pallet_wide = this.pallet_param.wide * (1 + this.pallet_param.over_limit / 100);
      let pallet_high = this.pallet_param.high * (1 + this.pallet_param.over_limit / 100);
      let fit_flg = true;
      let cd = 0;
      let kd = 0;
      let gd = 0;
      this.formInline.forEach((i) => {
        cd = i.bzcd - 0;
        kd = i.bzkd - 0;
        gd = i.bzgd - 0;
        // 如果有一边比托盘的任何一边长，报错
        if ((cd > pallet_long) & (cd > pallet_wide) & (cd > pallet_high)) {
          fit_flg = false;
        } else if (
            (kd > pallet_long) &
            (kd > pallet_wide) &
            (kd > pallet_high)
        ) {
          fit_flg = false;
        } else if (
            (gd > pallet_long) &
            (gd > pallet_wide) &
            (gd > pallet_high)
        ) {
          fit_flg = false;
        }
        // 转向对比
        if (
            ((cd <= pallet_long) & (kd <= pallet_wide) & (gd <= pallet_high)) |
            ((cd <= pallet_long) & (kd <= pallet_high) & (gd <= pallet_wide)) |
            ((cd <= pallet_wide) & (kd <= pallet_long) & (gd <= pallet_high)) |
            ((cd <= pallet_wide) & (kd <= pallet_high) & (gd <= pallet_long)) |
            ((cd <= pallet_high) & (kd <= pallet_wide) & (gd <= pallet_long)) |
            ((cd <= pallet_high) & (kd <= pallet_long) & (gd <= pallet_wide))
        ) {
          fit_flg = true;
        } else {
          fit_flg = false;
        }
      });

      return fit_flg;
    },

    /**
     * 运行托盘演示程序
     */
    async allocationPallet() {
      if (this.isLock) {
        return;
      }
      this.isLock = true;
      this.loading = true;
      let json_param = JSON.parse(JSON.stringify(this.formInline));

      let param = {
        RECORDS: json_param,
        pallet_param: this.pallet_param,
      };
      let empty_qty_flg = false;
      param.RECORDS.forEach((i) => {
        if ((i.qty == "") | (i.qty == null)) {
          empty_qty_flg = true;
        }

        if (
            (i.bzcd == "") |
            (i.bzkd == "") |
            (i.bzgd == "") |
            (i.bzcd == null) |
            (i.bzkd == null) |
            (i.bzgd == null)
        ) {
          this.$notify({
            title: "警告",
            message: "产品长宽高不能为空，请核对！",
            type: "warning",
          });
          this.isLock = false;
          this.loading = false;
          return;
        }
      });

      if (empty_qty_flg) {
        this.$notify({
          title: "警告",
          message: "产品数量不能为空，请核对！",
          type: "warning",
        });
        this.loading = false;
        this.isLock = false;
        return;
      }
      if (!this.validate_item_count()) {
        this.$notify({
          title: "警告",
          message: "产品总数不能超过 500，请核对！",
          type: "warning",
        });
        this.loading = false;
        this.isLock = false;
        return;
      }
      if (!this.validate_pallet()) {
        this.$notify({
          title: "警告",
          message: "产品规格超过托盘限制，请核对！",
          type: "warning",
        });
        this.loading = false;
        this.isLock = false;
        return;
      }
      // 开始处理时间
      let time_befor = new Date().getTime();
      await getAllocationPalletData(param).then((res) => {
        //取得托盘数组
        let palletArray = res.data;
        palletArray.forEach((i) => {
          let path = i.file_path.split("/");
          let index = path.length - 1;
          i.file_path = path[index];
        });
        this.palletArray = palletArray;
        //取得截图数组
        this.addPic(this.palletArray);
        this.showPalletInfo(this.palletArray);
        this.pallet_count = res.data.length;
        this.formInline[0].orientation = "" + res.forward_index;
        console.log(res.forward_index);
      });
      let time_after = new Date().getTime();
      this.time_count = (time_after - time_befor) / 1000;
      this.loading = false;
      this.isLock = false;
    },
    // 展示托盘详情
    async showPalletInfo(palletArray) {
      //把托盘的产品对象转化成数组，供表单显示
      let pallet_info_arry = [];

      for (let i = 0; i < palletArray.length; i++) {
        pallet_info_arry.push(
            await this.transfer_object_to_array(
                palletArray[i].per_item_count,
                palletArray[i].per_item_weight,
				palletArray[i].xyz
            )
        );
      }
      this.pallet_form_data = pallet_info_arry;

      // if (this.formInline[0].orientation == '') {
      // this.formInline[0].orientation = '3'
      // }
    },

    //把对象变成数组
    async transfer_object_to_array(per_item_count_object, per_item_weight, xyz) {
      let keys = Object.keys(per_item_count_object);
      let valuse = Object.values(per_item_count_object);
      let weight_valuse = Object.values(per_item_weight);
      var final_array = [];
      for (let i = 0; i < keys.length; i++) {
        let item = {
          cpbh: "",
          qty: 0,
          weight: 0,
		  xyz:"",
        };
        item.cpbh = keys[i];
        item.qty = valuse[i];
        item.weight = weight_valuse[i];
		item.xyz = xyz;
        final_array.push(item);
      }
      return final_array;
    },
  },
};
</script>

<style lang="less" scoped>
.page-title {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.pagination {
  text-align: center;
  margin-top: 10px;
}

.census div {
  padding: 0 4px;
  border-radius: 4px;
}

.census div:hover {
  background: #fff;
  cursor: auto;
}

.census.clickable div:hover {
  background: #ddd;
  cursor: pointer;
}

.census.clickable div.active {
  background: #409eff;
  color: #fff;
}

.census div span {
  vertical-align: middle;
  line-height: 22px;
  font-size: 14px;
}

.census div span:nth-child(even) {
  font-weight: 700;
  float: right;
}

/deep/ .el-card__header {
  padding: 8px 12px;
  font-weight: 700;
  font-size: 14px;
}

/deep/ .el-card__body {
  padding: 15px;
}

/deep/ .census-box .el-card__body {
  padding: 8px 15px;
}

/deep/ .el-form-item__label {
  width: 100px;
}

/deep/ .search-param .el-form-item--small.el-form-item {
  margin: 0;
}

/deep/ .el-table thead tr th {
  background: #f5f7fa;
  font-size: 14px;
}
</style>
